import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Image from "gatsby-image"
// ...GatsbyImageSharpFluid

const CommunityPage = () => {
  return (
    <Layout>
      <h2>Community page</h2>
    </Layout>
  )
}

export default CommunityPage
